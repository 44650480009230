<template>
  <div>
    <custom-snackbar
      ref="snackbar"
    />

    <v-data-table
      :headers="headers"
      :items="data"
      sort-by="id"
      sort-desc="true"
      class="elevation-1 p-5"
      :search="search.length >= 3 ? search : ''"
      :items-per-page="$dataTableConfig.getItemsPerPage()"
      :footer-props="$dataTableConfig.getFooterProps()"
      :loading-text="$dataTableConfig.getLoadText()"
      :page="page"
      :page-count="pageCount"
      :options.sync="options"
      :server-items-length="totalItems"
      :loading="loading"
    >
      <template v-slot:top>
        <v-toolbar
          flat
          class="d-flex flex-row-reverse"
        >
          <v-dialog
            v-model="dialog"
            transition="dialog-bottom-transition"
            max-width="600"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="info"
                dark
                outlined
                v-bind="attrs"
                v-on="on"
              >
                Adicionar Motivo de alteração de frete
                <v-icon dark>
                  mdi-plus
                </v-icon>
              </v-btn>
            </template>
            <v-card>
              <v-toolbar
                color="info"
                dark
              >
                <span class="headline">{{ formTitle }}</span>
              </v-toolbar>
              <v-form
                id="cadastro-motivo-frete"
                ref="form"
                lazy-validation
                v-model="valid"
                v-if="showForm"
                @submit.prevent="validate"
              >
                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col cols="12" class="pb-0">
                        <v-text-field
                          dense
                          outlined
                          autofocus
                          label="Descrição"
                          :maxlength="max"
                          :rules="pesquisarRule"
                          v-model.trim="item.nome"
                        />
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>

                <v-card-actions v-show="showForm">
                  <v-spacer />
                  <v-btn color="blue darken-1" text @click="close">Cancelar</v-btn>
                  <v-btn color="blue darken-1" text @click="validate">Salvar</v-btn>
                  <v-spacer />
                </v-card-actions>
              </v-form>

              <v-card-text v-else>
                <div class="text-center p-5">
                  <h4 class="mb-4">
                    Aguarde...
                  </h4>

                  <v-progress-circular
                    :size="100"
                    :width="2"
                    color="primary"
                    indeterminate
                  />
                </div>
              </v-card-text>
            </v-card>
          </v-dialog>

          <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card class="bg-danger text-white">
              <v-card-title class="headline word-breaker">
                <p>
                  Tem certeza que deseja excluir o Motivo
                  {{ item.nome }}?
                </p>
              </v-card-title>

              <v-card-text class="d-flex justify-center">
                <v-progress-circular
                  v-show="loadingDelete"
                  :size="50"
                  :width="6"
                  color="white"
                  indeterminate
                />
              </v-card-text>

              <v-card-actions v-show="!loadingDelete">
                <v-spacer />
                <v-btn color="white" text outlined large @click="closeDelete">Não</v-btn>
                <v-btn color="white" text outlined large @click="deleteItemConfirm">Sim</v-btn>
                <v-spacer />
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>

        <v-row class="mt-3">
          <v-col class="col-12 py-1 my-0">
            <v-text-field
              v-model="search"
              :maxlength="max"
              outlined
              dense
              label="Pesquisar"
              append-icon="mdi-magnify"
            />
          </v-col>
        </v-row>
      </template>

      <template v-slot:[`item.actions`]="{ item }">
         <v-icon
          small
          @click="deleteItem(item)"
        >
          mdi-delete
        </v-icon>
      </template>

      <template v-slot:no-data>
        <v-spacer />

        <div>
          <v-row class="justify-center mt-1">
            <v-col class="col-lg-6">
              <custom-alert text="Não há dados para serem exibidos"/>
            </v-col>
          </v-row>
        </div>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'
import ApiService from '@/core/services/api.service'

export default {
  data: () => ({
    valid: true,
    loading: false,
    loadingDelete: false,
    showForm: true,
    dialog: false,
    dialogDelete: false,
    max: 100,
    search: '',

    pesquisarRule: [
      (v) => !!(v ? v.trim() : v) || 'Necessário informar a descrição',
    ],

    data: [],
    item: {},
    itemToDelete: null,
  }),

  computed: {
    formTitle () {
      return 'Adicionar Motivo de alteração de frete'
    },

    headers () {
      return [
        { text: '#', align: 'start', value: 'id' },
        { text: 'Motivo', value: 'nome' },
        { text: 'Ações', value: 'actions', align: 'center' },
      ]
    },
  },

  watch: {
    dialog (val) {
      if (!val) {
        this.close()
      }

      if (this.$refs.form) {
        this.$refs.form.resetValidation()
      }
    },

    dialogDelete (val) {
      val || this.closeDelete()
    },

    options: {
      handler () {
        this.getData()
      },
    },
  },
  mounted () {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Configurações', route: 'configuracoes' },
      { title: 'Agrícola', route: 'configuracoes/agricola' },
      { title: 'Motivo de Alteração de Frete' },
    ])

    this.initialize()
  },
  methods: {
    async initialize () {
      this.getData()
    },

    validate () {
      if (this.$refs.form.validate()) {
        this.showForm = false

        ApiService.post('/motivos-de-alteracao-frete', {
          nome: this.item.nome,
        }).then((res) => {
          this.data.push(res.data.data)
          this.$refs.snackbar.show('Sucesso!', `Motivo ${this.item.nome} adicionado com sucesso`)

          this.getData()
          this.close()
        }).catch((error) => {
          if (error.response.data.status === 403) {
            this.$refs.snackbar.show('Alerta!', 'Não foi possível cadastrar. Descrição já está sendo utilizada!', 'alert')
          } else {
            this.$refs.snackbar.show('Erro', 'Não foi possível adicionar o Motivo', 'danger')
            console.error('Erro: ', error)
          }
        }).finally(() => {
          this.showForm = true
        })
      }
    },

    getData () {
      this.loading = true
      this.data = []

      ApiService.get('/motivos-de-alteracao-frete', '').then((res) => {
        const dados = res.data
        this.loading = false
        this.data = dados.data
      }).catch((error) => {
        this.loading = false
        console.error('Erro: ', error)
      })
    },

    deleteItem (item) {
      this.item = item
      this.itemToDelete = this.data.indexOf(item)
      this.dialogDelete = true
    },

    deleteItemConfirm () {
      this.loadingDelete = true

      ApiService.delete(`/motivos-de-alteracao-frete/${this.item.id}`).then(() => {
        this.data.splice(this.itemToDelete, 1)
        this.$refs.snackbar.show('Sucesso!', `Motivo ${this.item.nome} removido com sucesso`)
        this.closeDelete()
      }).catch((error) => {
        console.error('Erro: ', error)
        this.$refs.snackbar.show('Erro', 'Não foi possível remover o Motivo', 'danger')
        this.closeDelete()
      }).finally(() => {
        this.loadingDelete = false
        this.item = {}
      })
    },

    close () {
      this.dialog = false
      this.item = {}
    },

    closeDelete () {
      this.dialogDelete = false
      this.item = {}
    },
  },
}
</script>
